<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <notifications></notifications>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Reset password</small>
              </div>

              <form class="needs-validation" @submit.prevent="handleSubmit()">
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  required
                  placeholder="Email"
                  type="email"
                  name="email"
                  v-model="form.data.attributes.email"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />

                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  placeholder="New Password"
                  type="password"
                  name="New_password"
                  v-model="password"
                >
                </base-input>

                <div class="text-center">
                  <base-button 
                    type="primary" 
                    native-type="submit" 
                    class="my-4"
                    :class="{ loading_btn: loading === true }"
                    :disabled="!canSentLink"
                  >
                  <i v-if="loading" class="el-icon-loading mr-2"></i>
                    Reset Password
                  </base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError },
  data() {
    return {
      form: {
        data: {
          type: "password-reset",
          attributes: {
            password: "",
            token: "",
            email: "",
          },
        },
      },
      loading:false,
      password:"",
    };
  },
  mounted() {
    this.form.data.attributes.token = this.$route.query.waltoken;
  },
  beforeDestroy() {
    this.$router.replace({ query: null });
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      const user = {
        email:this.form.data.attributes.email,
        new_password:this.password,
      }

      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Reset-Pass-Token": this.form.data.attributes.token,
        },
      };
      try {
        const res = await this.$store.dispatch("createNewPassword", {user,requestOptions});
        console.log(res)
        if(res.data && res.data.status == "success"){
          this.$notify({
            type: "success",
            message: res.data.message,
          });
          this.form.data.attributes.email = ""
          this.form.data.attributes.password = ""
          this.form.data.attributes.token = ""
          this.loading = false
          this.$router.push({
            name: "login",
          });
        }else{
          this.$notify({
            type: "danger",
            message: "The verification token could not be verified; it may have expired. Please contact customer support <support@wring.dev> for help with resetting your password.",
          });
          this.loading = false
        }
      } catch (error) {
        await this.$notify({
          type: "danger",
          message: "The given data was invalid.",
        });
        this.loading = false
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
  computed:{ 
    canSentLink() {
      const emppry = this.password.length > 0 
      console.log(emppry)
      return emppry
    },
  },
};
</script>
<style lang="scss">
  .loading_btn {
    cursor: not-allowed;
    filter: grayscale(0.55);

    &:hover {
      cursor: not-allowed !important;
      transform: none;
    }
  }
</style>
